<template>
  <component :is="tab === 'uploadByImage' ? 'EinvoiceUploadByImage' : 'EinvoiceUploadManually'">
    <template v-slot:prepend>
      <CouponFilters v-model="tab" :options="tabOptions" class="s-mb-3"
        @input="(tab) => $router.replace({ query: { tab } })" />
    </template>
  </component>
</template>

<script>
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue"
import EinvoiceUploadManually from "@/pages/Liff/Einvoice/EinvoiceUploadManually"
import EinvoiceUploadByImage from "@/pages/Liff/Einvoice/EinvoiceUploadByImage"

export default {
  components: {
    CouponFilters,
    EinvoiceUploadManually,
    EinvoiceUploadByImage,
  },
  data: (vm) => ({
    tab: vm.$route.query.tab || "uploadManually",
  }),
  computed: {
    tabOptions() {
      return [
        { key: "uploadManually", text: '手動輸入', },
        { key: "uploadByImage", text: '上傳照片', },
      ]
    },
  },
};
</script>
